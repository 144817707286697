import classnames from 'classnames';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BANNERS,
  ROUTE_CHECKLISTS,
  ROUTE_HINTS,
  ROUTE_SURVEYS,
  ROUTE_TOURS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_DRAFT,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';
import EmptyState from './components/EmptyState';
import Header from './components/Header';
import ModalCreatePoke, {MODE_PREVIEW} from './components/ModalCreatePoke';
import PushesTable from './components/PushesTable';
import QuickAccess from './components/QuickAccess';
import {ViewEditor} from './components/ViewEditor';
import {ListView} from './components/ViewList';
import {PushesContext} from './context';

export const POKES_VALUES_PER_PAGE = 20;

const logger = new Swaler('Pushes');

export const TAB_ALL = 'All';
export const STATE_LIVE = 'Live';
export const STATE_PAUSED = 'Paused';
export const STATE_DRAFT = 'Draft';

const Pushes = () => {
  const match = useRouteMatch();

  const {view, pokeToRefresh, setViewEvolutionCount, setPokeToRefresh} =
    useContext(PushesContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [values, setValues] = useState({
    data: [],
    skip: 0,
    take: POKES_VALUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewPushModal, setShowNewPushModal] = useState(false);
  const [defaultMode, setDefaultMode] = useState(null);
  const [defaultTemplate, setDefaultTemplate] = useState(null);

  const cancelCtrl = useRef(null);

  let type = null;

  if (match.path === ROUTE_TOURS) {
    type = EVOLUTION_TYPE_TOUR;
  } else if (match.path === ROUTE_SURVEYS) {
    type = EVOLUTION_TYPE_SURVEY;
  } else if (match.path === ROUTE_BANNERS) {
    type = EVOLUTION_TYPE_BANNER;
  } else if (match.path === ROUTE_HINTS) {
    type = EVOLUTION_TYPE_HINT;
  } else if (match.path === ROUTE_CHECKLISTS) {
    type = EVOLUTION_TYPE_CHECKLIST;
  }

  useEffect(() => {
    const setup = async () => {
      if (cancelCtrl.current != null) {
        cancelCtrl.current.abort();
      }
      setValues({
        data: [],
        skip: 0,
        take: POKES_VALUES_PER_PAGE,
        total: 0,
      });
      await fetchEvolutions(0, true);
    };

    setup();
  }, [view, type]);

  useEffect(() => {
    const refresh = async () => {
      const refreshedPoke = await evolutionService.getEvolutionById(
        pokeToRefresh,
        {
          relations: ['steps', 'segments', 'analytics', 'tourSteps', 'tags'],
        }
      );

      setValues((values) => ({
        ...values,
        data: values.data.map((e) =>
          e.uid === refreshedPoke.uid ? refreshedPoke : e
        ),
      }));
      setPokeToRefresh(null);
    };

    if (pokeToRefresh != null) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pokeToRefresh]);

  const {data: hasAtLeastOne} = useQuery({
    queryKey: ['hasAtLeastOne', 'evolutions', type],
    queryFn: async () => {
      const res = await evolutionService.getEvolutions({
        type: type,
        take: 1,
      });

      return res.total > 0;
    },
    onError: (err) => {
      logger.error('Could not fetch evolutions, failed with err', err.message);
    },
  });

  const fetchEvolutions = async (pageParam = page, reset = false) => {
    cancelCtrl.current = new AbortController();
    setIsLoading(true);
    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await evolutionService.getEvolutions(
        {
          type: type,
          // Filters from the selected view
          // contexts: view.contexts, removing contexts from now on as we don't use them anymore
          states: view.states,
          segments: view.segments,
          tags: view.tags,

          // Handle draft a little different
          ...(view.states == null ||
          view.states.length === 0 ||
          view.states.includes(EVOLUTION_STATE_DRAFT)
            ? {}
            : {removeDrafts: true}),

          onlyPokes: true,
          relations: [
            'steps',
            'steps.only-first',
            'segments',
            'analytics',
            'tourSteps',
            'tourSteps.only-first',
            'tags',
          ],
          take: POKES_VALUES_PER_PAGE,
          skip: pageParam * POKES_VALUES_PER_PAGE,
          orderBy: 'createdAt',
        },
        {signal: cancelCtrl.current.signal}
      );

      setValues((prevState) => ({
        ...res,
        ...(reset === false ? {data: [...prevState.data, ...res.data]} : {}),
      }));
      setViewEvolutionCount(res.total);
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }

      const {code, title, message, actions} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetch evolutions failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      setIsLoading(false);
    }
  };

  const classNames = classnames('s-pushes', {
    'has-not-installed': project.hasSnippetInstalled === false,
  });

  const evolutions = values.data;

  return (
    <div className={`${classNames} fade-in`}>
      {hasAtLeastOne === true && (
        <>
          <Header setShowNewPushModal={setShowNewPushModal} />
          <QuickAccess setShowNewPushModal={setShowNewPushModal} />
          <ListView />
          <ViewEditor />
        </>
      )}

      {isLoading && evolutions?.length === 0 ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <div className="pushes-row fade-in">
          {evolutions?.length <= 0 ? (
            <>
              {hasAtLeastOne === true ? (
                <EmptyStateBlock
                  img={EmptyStateImgs.EmptyResults}
                  title={'No results found'}
                  description="Refine your filters to find what you're looking for"
                />
              ) : (
                <EmptyState
                  onCreateNewClick={() => {
                    setShowNewPushModal(true);
                  }}
                  onTemplateClick={(template) => {
                    setDefaultTemplate(template);
                    setDefaultMode(MODE_PREVIEW);
                    setShowNewPushModal(true);
                  }}
                />
              )}
            </>
          ) : (
            <PushesTable
              pushes={evolutions}
              onPushDelete={(pokeId) => {
                setValues((values) => ({
                  ...values,
                  data: values.data.filter((poke) => poke.uid !== pokeId),
                  total: values.total - 1,
                }));
              }}
              onPushUpdateState={(pokeId, state) => {
                setValues((values) => ({
                  ...values,
                  data: values.data.map((poke) => {
                    if (poke.uid === pokeId) {
                      return {
                        ...poke,
                        state,
                      };
                    }
                    return poke;
                  }),
                }));
                setPokeToRefresh(pokeId);
              }}
              isLoading={isLoading}
              page={page}
              total={values.total}
              fetchValues={fetchEvolutions}
            />
          )}
        </div>
      )}
      {showNewPushModal === true && (
        <ModalCreatePoke
          isOpen={showNewPushModal}
          onRequestClose={() => {
            setShowNewPushModal(false);
            setDefaultMode(null);
            setDefaultTemplate(null);
          }}
          type={type}
          defaultMode={defaultMode}
          defaultTemplate={defaultTemplate}
          resetDefault={() => {
            setDefaultMode(null);
            setDefaultTemplate(null);
          }}
        />
      )}
    </div>
  );
};

export default Pushes;
