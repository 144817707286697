import Button from 'components/Button';
import InputGroup from 'components/Input';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {errorCodes} from 'helpers/error';
import {AVAILABLE_LANGUAGES} from 'helpers/languages';
import {useRef, useState} from 'react';
import {fileService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('MediaBlockInput');

const MediaBlockInput = ({selectedLanguage, block, onChange}) => {
  const {value, file, type: blockType} = block;
  const [type, fileUrl, altText, sizing] = value.split(';');

  const [isUploading, setIsUploading] = useState(false);

  const refInputFile = useRef();

  const isImage = type === 'image';
  const isVideo = type === 'video';

  const isUrlMode = file == null;

  const handleChange = (value) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      onChange({
        translations: hasLanguage
          ? block.translations.map((t) => {
              if (t.language === selectedLanguage) {
                return {
                  ...t,
                  value,
                };
              } else {
                return t;
              }
            })
          : [
              ...(block?.translations || []),
              {language: selectedLanguage, value},
            ],
      });
    } else {
      onChange({value: `${type};${value};${altText};${sizing}`});
    }
  };

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      onChange({
        value: `${type};${file.publicUrl};${altText};${sizing}`,
        file,
      });

      if (selectedLanguage != null) {
        const hasLanguage =
          block.translations?.find((t) => t.language === selectedLanguage) !=
          null;
        onChange({
          translations: hasLanguage
            ? block.translations.map((t) => {
                if (t.language === selectedLanguage) {
                  return {
                    ...t,
                    value: file.publicUrl,
                    file,
                  };
                } else {
                  return t;
                }
              })
            : [
                ...(block?.translations || []),
                {language: selectedLanguage, value: file.publicUrl, file},
              ],
        });
      } else {
        onChange({
          value: `${type};${file.publicUrl};${altText};${sizing}`,
          file,
        });
      }
    }
  };

  const handleDeleteFile = async () => {
    if (selectedLanguage != null) {
      onChange({
        translations: block.translations.map((t) => {
          if (t.language === selectedLanguage) {
            return {
              ...t,
              value: null,
              file: null,
            };
          } else {
            return t;
          }
        }),
      });
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType: blockType,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if (
        [
          errorCodes.FILE_TYPE_NOT_AUTHORIZED,
          errorCodes.FILE_TYPE_NOT_DETECTED,
          'file too large',
        ].includes(code) === false
      ) {
        logger.error('Failed to upload file', code);
      }
      toastDanger([title, message], {actions});
      return null;
    }
  };

  if (isVideo) {
    const inputValue =
      selectedLanguage != null
        ? block?.translations?.find((t) => t.language === selectedLanguage)
            ?.value || ''
        : fileUrl;

    return (
      <InputGroup
        value={inputValue}
        onChange={({target}) => {
          handleChange(target.value);
        }}
        placeholder={fileUrl || 'https://loom.com/yourvideo'}
        className="media-content-input"
      />
    );
  } else if (isImage) {
    if (isUrlMode === true) {
      const inputValue =
        selectedLanguage != null
          ? block?.translations?.find((t) => t.language === selectedLanguage)
              ?.value || ''
          : fileUrl;

      return (
        <InputGroup
          value={inputValue}
          onChange={({target}) => {
            handleChange(target.value);
          }}
          placeholder={fileUrl || 'https://www.yourimage.com'}
          className="media-content-input"
        />
      );
    } else {
      const fileValue =
        selectedLanguage != null
          ? block?.translations?.find((t) => t.language === selectedLanguage)
              ?.file || file
          : file;
      const languageObject = AVAILABLE_LANGUAGES.find(
        (l) => l.code === selectedLanguage
      );

      return (
        <>
          <input
            ref={refInputFile}
            type="file"
            style={{display: 'none'}}
            onChange={handleFileChange}
          />
          <div className="media-block-image-input-wrapper">
            <img src={fileValue?.publicUrl} alt="Media" />
            <div className="image-input-actions">
              {selectedLanguage != null && (
                <>
                  <Button
                    thin
                    className="image-input-action"
                    onClick={() => refInputFile.current.click()}>
                    Change for {languageObject?.label?.toLowerCase()}
                  </Button>
                  <Button
                    thin
                    className="image-input-action"
                    onClick={handleDeleteFile}>
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default MediaBlockInput;
