import {
  EVENT_DELETE_EVOLUTION,
  EVENT_DUPLICATE_EVOLUTION,
  EVENT_EDIT_EVOLUTION,
  EVENT_PAUSE_POKE,
  EVENT_PLAY_POKE,
} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import LivePreview from 'components/LivePreview';
import LivePreviewNew from 'components/LivePreviewNew';
import DefaultLoader from 'components/Loader';
import {Modal, ModalConfirm} from 'components/Modal';
import ModalInstall from 'components/ModalInstall';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {PermissionsPoke} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {errorCodes} from 'helpers/error';
import {hasPermissions} from 'helpers/permission';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {array, bool, func, number} from 'prop-types';
import {forwardRef, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeGrid as Grid} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  ROUTE_BANNER_WITH_ID,
  ROUTE_BOOSTED_WITH_ID,
  ROUTE_BUILDER_AUDIENCE,
  ROUTE_BUILDER_CREATE,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_HINT_WITH_ID,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POKE_SETTINGS_FROM_TYPE,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOUR_WITH_ID,
} from 'router/routes.const';
import {PushesContext} from 'scenes/Pushes/context';
import {generalSelector, miscSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_SHOW_ON_WIDGET,
  F_OPTION_V2,
} from 'services/evolution';
import {
  VIEW_LAYOUT_GRID,
  VIEW_LAYOUT_ROW,
  VIEW_LAYOUT_ROW_LARGE,
} from 'services/evolution-list-view';
import {F_EXTRA_CAN_PUBLISH} from 'services/project';
import {PLAN_GROWTH_ID, PLAN_STARTUP_ID} from 'services/subscription';
import {TAG_CONTEXT_VIEW} from 'services/tag';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  pushes: array,
  total: number,
  page: number,
  onPushDelete: func,
  onPushUpdateState: func,
  fetchValues: func,
  isLoading: bool,
};

const defaultProps = {
  pushes: [],
  total: 0,
  page: 0,
  onPushDelete: () => {},
  onPushUpdateState: () => {},
  fetchValues: () => {},
  isLoading: false,
};

const logger = new Swaler('PushesTable');

const MARGIN_SIZE = 8;

const PushesTable = ({
  pushes,
  onPushDelete,
  onPushUpdateState,
  total,
  page,
  fetchValues,
  isLoading,
}) => {
  const {view} = useContext(PushesContext);
  const history = useHistory();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const defaultViewLayout = useSelector((state) =>
    miscSelector.getDefaultViewLayout(state)
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingState, setIsTogglingState] = useState(false);
  const [showModalDeleteEvolution, setShowModalDeleteEvolution] =
    useState(false);
  const [
    showModalOptionalDeleteEvolution,
    setShowModalOptionalDeleteEvolution,
  ] = useState(false);
  const [evolutionIdToDelete, setEvolutionIdToDelete] = useState(null);
  const [showModalInstall, setShowModalInstall] = useState(false);

  const handleDuplicate = async (evolutionId) => {
    amplitude.getInstance().logEvent(EVENT_DUPLICATE_EVOLUTION, {
      from: 'PushesTable',
    });
    const createdEvolution = await evolutionService.duplicateEvolution(
      evolutionId
    );
    if (
      [
        EVOLUTION_TYPE_TOUR,
        EVOLUTION_TYPE_SURVEY,
        EVOLUTION_TYPE_BANNER,
        EVOLUTION_TYPE_HINT,
        EVOLUTION_TYPE_CHECKLIST,
      ].includes(createdEvolution.type)
    ) {
      history.push(
        ROUTE_POKE_BUILDER_FROM_TYPE(
          createdEvolution.uid,
          createdEvolution.type
        )
      );
    } else {
      history.push(ROUTE_BUILDER_CREATE({evolutionId: createdEvolution.uid}));
    }
  };

  const handleRemovePoke = async () => {
    setIsDeleting(true);
    try {
      await evolutionService.updateEvolution(evolutionIdToDelete, {
        boostFlags: 0,
      });

      toastSuccess('Experience deleted 👍', {toastId: 'poke-deleted'});
      onPushDelete(evolutionIdToDelete);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting experience failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setEvolutionIdToDelete(null);
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
    }
  };

  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      amplitude.getInstance().logEvent(EVENT_DELETE_EVOLUTION, {
        from: 'PushesTable',
      });
      await evolutionService.deleteEvolution(evolutionIdToDelete);

      toastSuccess('Experience deleted 👍', {toastId: 'evolution-deleted'});
      onPushDelete(evolutionIdToDelete);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setEvolutionIdToDelete(null);
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
    }
  };

  const handleToggleState = async (evolutionId, live) => {
    const state = live === true ? EVOLUTION_STATE_LIVE : EVOLUTION_STATE_PAUSED;

    if (live === true) {
      if (project.snippetInstalledAt == null) {
        return setShowModalInstall(true);
      }
      if (hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false) {
        return update({
          planId: PLAN_STARTUP_ID,
          title: 'Publish your experiences',
          description:
            'Unlock the ability to publish your experiences and reach your users with the Startup plan.',
        });
      }
    }
    setIsTogglingState(true);
    try {
      if (live === true) {
        amplitude.getInstance().logEvent(EVENT_PLAY_POKE, {
          from: 'PushesTable',
        });
      } else {
        amplitude.getInstance().logEvent(EVENT_PAUSE_POKE, {
          from: 'PushesTable',
        });
      }
      await evolutionService.updateEvolutionState(evolutionId, state);

      toastSuccess('Experience updated 👍', {toastId: 'poke-updated'});
      onPushUpdateState(evolutionId, state);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if (code === errorCodes.LIVE_SURVEYS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited surveys',
          description: 'Publish unlimited surveys with the Growth plan.',
        });
      }
      if (code === errorCodes.LIVE_CHECKLIST_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited checklists',
          description: 'Publish unlimited checklists with the Growth plan.',
        });
      }
      logger.error('Updating experience failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsTogglingState(false);
    }
  };

  const shouldSeeActions = hasPermissions([
    [
      PermissionsPoke.CREATE_EDIT,
      PermissionsPoke.DELETE,
      PermissionsPoke.PUBLISH,
    ],
  ]);
  const viewLayout = view.layout ?? defaultViewLayout;

  const sortedPokes = pushes.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const isItemLoaded = (index) => index < sortedPokes.length;

  const Item = ({data: pokes, style, columnIndex, rowIndex}) => {
    const POKE_BY_ROW = viewLayout === VIEW_LAYOUT_GRID ? 3 : 1;
    const i = rowIndex * POKE_BY_ROW + columnIndex * 1;

    if (!isItemLoaded(i) && i < total) {
      return (
        <div
          className="push"
          key={i}
          style={{
            ...style,
            ...(viewLayout === VIEW_LAYOUT_GRID
              ? {
                  top: `${parseFloat(style.top) + MARGIN_SIZE}px`,
                  height: `${parseFloat(style.height) - MARGIN_SIZE * 2}px`,
                }
              : {
                  top: `${parseFloat(style.top)}px`,
                  height: `${parseFloat(style.height)}px`,
                }),
            width: `${parseFloat(style.width) - MARGIN_SIZE * 2}px`,
            left: `${parseFloat(style.left) + MARGIN_SIZE}px`,
          }}>
          <DefaultLoader />
        </div>
      );
    }
    const poke = pokes[i];
    if (poke == null) {
      return <></>;
    }

    const {
      uid: pushId,
      title,
      createdAt,
      optionsFlags,
      onTheFlySegment,
      segments,
      analytics,
      isDraft,
      state,
      lastStepChangeAt,
      expiresAt,
      tags,
      type,
    } = poke;

    poke.tourSteps?.forEach((s) => {
      const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
      s.tourIndexOrder = tourIndexOrder;
    });
    poke.tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

    const shownOnWidget = hasFlag(F_OPTION_SHOW_ON_WIDGET, optionsFlags);
    const shownOnPortal = hasFlag(F_OPTION_SHOW_ON_PORTAL, optionsFlags);

    const audience =
      onTheFlySegment != null
        ? 'On the fly segmentation'
        : segments?.length > 0
        ? segments.map((s) => s.name).join(', ')
        : 'All users';

    const isLive =
      isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
    const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
    const isScheduled =
      isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
    const isExpired =
      isDraft !== true &&
      expiresAt != null &&
      dayjs(expiresAt).isBefore(dayjs());
    const isOldVersionPoke = hasFlag(F_OPTION_V2, poke.optionsFlags) !== true;

    const hasAccessToAnalytics = hasPermissions(PermissionsPoke.ANALYTICS);

    const getPokeRoute = () => {
      if (type === EVOLUTION_TYPE_TOUR) {
        return ROUTE_TOUR_WITH_ID(pushId);
      } else if (type === EVOLUTION_TYPE_SURVEY) {
        return ROUTE_SURVEY_WITH_ID(pushId);
      } else if (type === EVOLUTION_TYPE_BANNER) {
        return ROUTE_BANNER_WITH_ID(pushId);
      } else if (type === EVOLUTION_TYPE_HINT) {
        return ROUTE_HINT_WITH_ID(pushId);
      } else if (type === EVOLUTION_TYPE_CHECKLIST) {
        return ROUTE_CHECKLIST_WITH_ID(pushId);
      }
    };

    const linkTo =
      hasAccessToAnalytics === true
        ? shownOnPortal === true || isOldVersionPoke === true
          ? ROUTE_BOOSTED_WITH_ID(pushId)
          : getPokeRoute()
        : '#';

    const previewPoke =
      poke?.tourSteps?.filter((ts) =>
        ts.steps?.some((s) => s.removed !== true)
      )[0] || poke;
    const isBoosted =
      hasFlag(F_OPTION_SHOW_ON_PORTAL, poke.optionsFlags) === true;
    const Actions =
      shouldSeeActions === true ? (
        <PokeAction
          poke={poke}
          viewLayout={viewLayout}
          isDraft={isDraft}
          isExpired={isExpired}
          isScheduled={isScheduled}
          isPaused={isPaused}
          isLive={isLive}
          isTogglingState={isTogglingState}
          isOldVersionPoke={isOldVersionPoke}
          isBoosted={isBoosted}
          shownOnPortal={shownOnPortal}
          shownOnWidget={shownOnWidget}
          onToggleState={handleToggleState}
          onDuplicate={handleDuplicate}
          setEvolutionIdToDelete={setEvolutionIdToDelete}
          setShowModalOptionalDeleteEvolution={
            setShowModalOptionalDeleteEvolution
          }
          setShowModalDeleteEvolution={setShowModalDeleteEvolution}
        />
      ) : (
        <></>
      );
    previewPoke.steps = previewPoke.steps?.sort(
      (a, b) => a.indexOrder - b.indexOrder
    );

    // Layout : Row Large
    if (viewLayout === VIEW_LAYOUT_ROW_LARGE || viewLayout == null) {
      return (
        <Link to={linkTo}>
          <div
            className={classnames('push', {})}
            key={pushId}
            style={{
              ...style,
              top: `${parseFloat(style.top)}px`,
              height: `${parseFloat(style.height)}px`,
              width: `${parseFloat(style.width)}px`,
              left: `${parseFloat(style.left)}px`,
            }}>
            <PokePreview
              poke={poke}
              previewPoke={previewPoke}
              view={view}
              viewLayout={viewLayout}
              isBoosted={isBoosted}
              isOldVersionPoke={isOldVersionPoke}
              isExpired={isExpired}
              isScheduled={isScheduled}
              isLive={isLive}
              isPaused={isPaused}
            />
            <div className="infos">
              <div className="push-title-wrapper">
                <div className="push-title">
                  {poke.isBoostOf != null && (
                    <Tooltip
                      className="tooltip-boosted-pushes-table"
                      offsetY={4}
                      trigger={
                        <div className="boosted-icon-wrapper">
                          <i className="icon-send-o" />
                        </div>
                      }>
                      Linked to "{poke.isBoostOf.title}"{' '}
                    </Tooltip>
                  )}
                  {title} {shownOnPortal && <i className="isax isax-link-2" />}
                </div>
                <div className="created-at">
                  Created {dayjs(createdAt).fromNow()}
                </div>
              </div>
              <PokeDetails viewLayout={viewLayout} tags={tags} />
            </div>
            <PokeAudience
              audience={audience}
              analytics={analytics}
              experienceType={type}
            />
            {Actions}
          </div>
        </Link>
      );
    }

    // Layout : Row
    if (viewLayout === VIEW_LAYOUT_ROW) {
      return (
        <Link to={linkTo}>
          <div
            className={classnames('push', {})}
            key={pushId}
            style={{
              ...style,
              top: `${parseFloat(style.top)}px`,
              height: `${parseFloat(style.height)}px`,
              width: `${parseFloat(style.width) - MARGIN_SIZE * 2}px`,
              left: `${parseFloat(style.left)}px`,
            }}>
            <PokeStatus
              isExpired={isExpired}
              isScheduled={isScheduled}
              isLive={isLive}
              isPaused={isPaused}
            />
            <div className="infos">
              <div className="push-title-wrapper">
                <div className="push-title">
                  {poke.isBoostOf != null && (
                    <Tooltip
                      className="tooltip-boosted-pushes-table"
                      offsetY={4}
                      trigger={
                        <div className="boosted-icon-wrapper">
                          <i className="icon-send-o" />
                        </div>
                      }>
                      Linked to "{poke.isBoostOf.title}"{' '}
                    </Tooltip>
                  )}
                  {title} {shownOnPortal && <i className="isax isax-link-2" />}
                </div>
                <div className="created-at">
                  Created {dayjs(createdAt).fromNow()}
                </div>
              </div>
            </div>
            <div className="audience-tags-wrapper">
              <PokeTags tags={tags} />
              <PokeAudience
                audience={audience}
                experienceType={type}
                analytics={analytics}
              />
            </div>
            {Actions}
          </div>
        </Link>
      );
    }

    // Layout : Grid
    if (viewLayout === VIEW_LAYOUT_GRID) {
      return (
        <Link to={linkTo}>
          <div
            className={classnames('push', {})}
            key={pushId}
            style={{
              ...style,
              top: `${parseFloat(style.top) + MARGIN_SIZE}px`,
              height: `${parseFloat(style.height) - MARGIN_SIZE * 2}px`,
              width: `${parseFloat(style.width) - MARGIN_SIZE * 2}px`,
              left: `${parseFloat(style.left)}px`,
            }}>
            <PokePreview
              poke={poke}
              previewPoke={previewPoke}
              view={view}
              viewLayout={viewLayout}
              isBoosted={isBoosted}
              isOldVersionPoke={isOldVersionPoke}
              isExpired={isExpired}
              isScheduled={isScheduled}
              isLive={isLive}
              isPaused={isPaused}
            />
            <div className="infos">
              <div className="push-title-wrapper">
                <PokeDetails viewLayout={viewLayout} tags={tags} />
                <div className="push-title">
                  {poke.isBoostOf != null && (
                    <Tooltip
                      className="tooltip-boosted-pushes-table"
                      offsetY={4}
                      trigger={
                        <div className="boosted-icon-wrapper">
                          <i className="icon-send-o" />
                        </div>
                      }>
                      Linked to "{poke.isBoostOf.title}"{' '}
                    </Tooltip>
                  )}
                  {title} {shownOnPortal && <i className="isax isax-link-2" />}
                </div>
                <div className="created-at">
                  Created {dayjs(createdAt).fromNow()}
                </div>
              </div>
            </div>
            <PokeAudience
              audience={audience}
              experienceType={type}
              analytics={analytics}
            />
            {Actions}
          </div>
        </Link>
      );
    }
    return <></>;
  };

  const innerElementType = forwardRef(({style, ...rest}, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height) + MARGIN_SIZE * 2}px`,
        width: `${parseFloat(style.width) + MARGIN_SIZE * 2}px`,
      }}
      {...rest}
    />
  ));

  return (
    <div
      className={classnames('poke-list-wrapper', {
        'layout-row': viewLayout === VIEW_LAYOUT_ROW,
        'layout-row-large':
          viewLayout === VIEW_LAYOUT_ROW_LARGE || viewLayout == null,
        'layout-grid': viewLayout === VIEW_LAYOUT_GRID,
      })}>
      <AutoSizer>
        {({height, width}) => {
          const NUM_COLUMNS = viewLayout === VIEW_LAYOUT_GRID ? 3 : 1;
          const NUM_ROWS =
            viewLayout === VIEW_LAYOUT_GRID ? Math.ceil(total / 3, 10) : total;
          const WIDTH_COLUMN =
            viewLayout === VIEW_LAYOUT_GRID
              ? Math.floor(width / 3, 10)
              : width - MARGIN_SIZE;
          const HEIGHT_ROW =
            viewLayout === VIEW_LAYOUT_GRID
              ? 380
              : viewLayout === VIEW_LAYOUT_ROW_LARGE
              ? 200
              : 100;

          return (
            <InfiniteLoader
              isItemLoaded={(index) => {
                const isLoaded = !!sortedPokes[index];
                return isLoaded;
              }}
              itemCount={total}
              loadMoreItems={() => {
                if (pushes.length < total && isLoading !== true) {
                  fetchValues(page + 1);
                }
              }}
              threshold={1}>
              {({onItemsRendered, ref}) => (
                <Grid
                  className="poke-list"
                  height={height}
                  width={width}
                  onItemsRendered={(gridProps) => {
                    onItemsRendered({
                      overscanStartIndex:
                        gridProps.overscanRowStartIndex * NUM_COLUMNS,
                      overscanStopIndex:
                        gridProps.overscanRowStopIndex * NUM_COLUMNS,
                      visibleStartIndex:
                        gridProps.visibleRowStartIndex * NUM_COLUMNS,
                      visibleStopIndex:
                        gridProps.visibleRowStopIndex * NUM_COLUMNS,
                    });
                  }}
                  ref={ref}
                  itemData={sortedPokes}
                  innerElementType={innerElementType}
                  columnCount={NUM_COLUMNS}
                  columnWidth={WIDTH_COLUMN}
                  rowCount={NUM_ROWS}
                  rowHeight={HEIGHT_ROW}>
                  {Item}
                </Grid>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
      <Modal
        title="Remove boosted poke?"
        className="modal-optional-delete"
        isOpen={showModalOptionalDeleteEvolution}
        onRequestClose={() => setShowModalOptionalDeleteEvolution(false)}>
        <div className="content">
          This experience is linked to a post in your Changelog. Deleting your
          experience will only remove the in-app element displayed to your users
          but the post in your Changelog will remain.
        </div>
        <div className="action-btns">
          <Button
            muted
            onClick={() => setShowModalOptionalDeleteEvolution(false)}>
            Cancel
          </Button>
          <div className="delete-btns">
            <Button primary onClick={handleRemovePoke}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <ModalConfirm
        isOpen={showModalDeleteEvolution}
        isConfirming={isDeleting}
        confirmText="Yes, delete"
        onConfirm={handleDeleteEvolution}
        onCancel={() => setShowModalDeleteEvolution(false)}
        onRequestClose={() => setShowModalDeleteEvolution(false)}
      />
      <ModalInstall
        isOpen={showModalInstall}
        onRequestClose={() => setShowModalInstall(false)}
        withCloseButton></ModalInstall>
    </div>
  );
};

PushesTable.propTypes = propTypes;
PushesTable.defaultProps = defaultProps;

export default PushesTable;

export const PokePreview = ({
  viewLayout,
  poke,
  isExpired,
  isScheduled,
  isLive,
  isPaused,
  isOldVersionPoke,
  isBoosted,
  previewPoke,
}) => {
  return (
    <div key={viewLayout} className="preview">
      <PokeStatus
        isExpired={isExpired}
        isScheduled={isScheduled}
        isPaused={isPaused}
        isLive={isLive}
      />
      {isOldVersionPoke === true || isBoosted === true ? (
        <LivePreview boostedEvolution={previewPoke} isScaledDownPreview />
      ) : previewPoke.steps[0]?.uid != null ? (
        <LivePreviewNew
          evolution={poke}
          poke={previewPoke}
          isScaledDownPreview
          selectedStepId={previewPoke.steps[0]?.uid}
        />
      ) : (
        <div className="empty-steps subtitle-4 n-600">No steps added yet</div>
      )}
      {isOldVersionPoke === true && isBoosted !== true && (
        <div className="deprecated-wrapper">
          <div className="deprecated-label">Deprecated</div>
        </div>
      )}
    </div>
  );
};

export const PokeDetails = ({viewLayout, tags = []}) => {
  return (
    <div
      className={classnames('poke-details-wrapper', {
        'in-grid-layout': viewLayout === VIEW_LAYOUT_GRID,
        'in-grid-row': viewLayout === VIEW_LAYOUT_ROW,
        'in-grid-row-large': viewLayout === VIEW_LAYOUT_ROW_LARGE,
      })}>
      <div className="poke-type-wrapper">
        <PokeTags tags={tags} />
      </div>
    </div>
  );
};

const PokeAction = ({
  poke,
  viewLayout,
  isDraft,
  isExpired,
  isScheduled,
  isPaused,
  isLive,
  isTogglingState,
  isOldVersionPoke,
  isBoosted,
  shownOnPortal,
  shownOnWidget,
  onToggleState,
  onDuplicate,
  setEvolutionIdToDelete,
  setShowModalOptionalDeleteEvolution,
  setShowModalDeleteEvolution,
}) => {
  const history = useHistory();

  const {uid, type} = poke;

  const [hasMenuOpen, setHasMenuOpen] = useState(false);

  const handleChangeState = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onToggleState(uid, isLive ? false : true);
  };
  const handleDuplicate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onDuplicate(uid);
  };
  const handleSettingsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (shownOnPortal) {
      return history.push(ROUTE_BUILDER_AUDIENCE(uid));
    }

    history.push(ROUTE_POKE_SETTINGS_FROM_TYPE(uid, type));
  };
  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
      from: 'PushesTable',
    });
    if (shownOnPortal) {
      return history.push(ROUTE_BUILDER_CREATE({evolutionId: uid}));
    }
    history.push(ROUTE_POKE_BUILDER_FROM_TYPE(uid, type));
  };
  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setEvolutionIdToDelete(uid);
    if (shownOnPortal === true || shownOnWidget) {
      return setShowModalOptionalDeleteEvolution(true);
    }
    setShowModalDeleteEvolution(true);
  };

  const canPublish = hasPermissions(PermissionsPoke.PUBLISH);
  const canEdit = hasPermissions(PermissionsPoke.CREATE_EDIT);
  const canDelete = hasPermissions(PermissionsPoke.DELETE);

  return (
    <div
      className="actions"
      onMouseLeave={() =>
        viewLayout === VIEW_LAYOUT_GRID ? setHasMenuOpen(false) : null
      }>
      {viewLayout === VIEW_LAYOUT_GRID ? (
        <>
          <div className="menu-poke-top">
            {isDraft !== true &&
              isExpired === false &&
              isScheduled === false &&
              canPublish && (
                <Button
                  className={classnames('toggle-state-btn', {
                    resume: isPaused,
                    pause: isLive,
                  })}
                  loading={isTogglingState}
                  iconLeft={isLive ? 'icon-pause-rounded' : 'icon-play-rounded'}
                  onClick={handleChangeState}>
                  {isLive ? 'Pause' : 'Play'}
                </Button>
              )}
            <Button
              iconOnly
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHasMenuOpen(!hasMenuOpen);
              }}>
              <i className="icon-horizontal-menu"></i>
            </Button>
          </div>
          <div
            className={classnames('menu-poke-actions', {
              'is-hidden': hasMenuOpen === false,
            })}>
            {isOldVersionPoke === true ||
            (isBoosted === true && isBoosted !== true) ? (
              <></>
            ) : (
              <>
                {canEdit && (
                  <Button iconLeft="icon-edit-outline" onClick={handleEdit}>
                    Edit
                  </Button>
                )}
                {(canEdit || canPublish) && (
                  <Button
                    iconLeft="icon-settings"
                    onClick={handleSettingsClick}>
                    Settings
                  </Button>
                )}

                {isBoosted !== true && canEdit && (
                  <Button iconLeft="icon-duplicate" onClick={handleDuplicate}>
                    Duplicate
                  </Button>
                )}
              </>
            )}
            {canDelete && (
              <Button danger iconLeft="icon-trash" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          {isDraft !== true &&
            isExpired === false &&
            isScheduled === false &&
            canPublish && (
              <Button
                className={classnames('toggle-state-btn', {
                  resume: isPaused,
                  pause: isLive,
                })}
                loading={isTogglingState}
                iconLeft={isLive ? 'icon-pause-rounded' : 'icon-play-rounded'}
                onClick={handleChangeState}>
                {isLive ? 'Pause' : 'Play'}
              </Button>
            )}
          {isOldVersionPoke === true ||
          (isBoosted === true && isBoosted !== true) ? (
            <></>
          ) : (
            <>
              {canEdit && (
                <Button iconLeft="icon-edit-outline" onClick={handleEdit}>
                  Edit
                </Button>
              )}
              {(canEdit || canPublish) && (
                <Button iconLeft="icon-settings" onClick={handleSettingsClick}>
                  Settings
                </Button>
              )}
              {isBoosted !== true && canEdit && (
                <Button iconLeft="icon-duplicate" onClick={handleDuplicate}>
                  Duplicate
                </Button>
              )}
            </>
          )}
          {canDelete && (
            <Button danger iconLeft="icon-trash" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const PokeTags = ({tags}) => {
  return tags
    .filter((t) => t.context === TAG_CONTEXT_VIEW)
    .map((t) => (
      <div
        className="tag-view"
        style={{
          border: `1px solid ${t.color}`,
          backgroundColor: `${t.color}20`,
          color: t.color,
        }}>
        {t.name}
      </div>
    ));
};

export const PokeStatus = ({isExpired, isScheduled, isLive, isPaused}) => {
  return (
    <div className="poke-status-tag-wrapper">
      {isExpired ? (
        <div className="status-tag tag-expired">Expired</div>
      ) : isScheduled ? (
        <div className="status-tag tag-scheduled">Scheduled</div>
      ) : isLive ? (
        <div className="status-tag tag-live">Active</div>
      ) : isPaused ? (
        <div className="status-tag tag-paused">Paused</div>
      ) : (
        <div className="status-tag tag-draft">Draft</div>
      )}
    </div>
  );
};

export const PokeAudience = ({audience, analytics, experienceType}) => {
  const isTour = experienceType === EVOLUTION_TYPE_TOUR;
  const isHint = experienceType === EVOLUTION_TYPE_HINT;
  const isBanner = experienceType === EVOLUTION_TYPE_BANNER;
  const isSurvey = experienceType === EVOLUTION_TYPE_SURVEY;
  const isChecklist = experienceType === EVOLUTION_TYPE_CHECKLIST;

  const value = isTour
    ? analytics?.uniqueTourStartedCount || 0
    : isHint
    ? analytics?.uniqueHintGroupSeenCount || 0
    : isBanner
    ? parseInt(analytics?.uniqueBannerSeenCount || 0, 10) +
      parseInt(analytics?.uniqueTourStartedCount || 0, 10) // legacy count stored in uniqueTourStartedCount
    : isSurvey
    ? parseInt(analytics?.uniqueSurveyStartedCount || 0, 10) +
      parseInt(analytics?.uniqueViewsCount || 0, 10) // legacy count stored in uniqueViewsCount
    : isChecklist
    ? parseInt(analytics?.checklistStartedCount || 0, 10)
    : 0;

  return (
    <div className="audience">
      <div className="poke-stats-wrapper">
        <i className="icon-users" />
        {audience} -&nbsp;
        <div>{value} reached</div>
      </div>
    </div>
  );
};
