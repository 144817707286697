import {
  EVENT_DELETE_EVOLUTION,
  EVENT_EDIT_EVOLUTION,
  EVENT_PAUSE_POKE,
  EVENT_PLAY_POKE,
} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  expiredTag,
  scheduledTag,
} from 'components/EvolutionList/components/Evolution';
import ListLastUsersReached from 'components/ListLastUsersReached';
import Loader from 'components/Loader';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import Tabs from 'components/Tabs';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {evolutionIcons} from 'helpers/poke';
import useScrollDetector from 'hooks/UseScrollDetector';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BOOSTED_WITH_ID,
  ROUTE_BUILDER_CREATE,
  ROUTE_FEED,
  ROUTE_FEED_EVOLUTION,
  ROUTE_POST_BUILDER_WRITER,
  ROUTE_ROADMAP,
  ROUTE_ROADMAP_EVOLUTION,
  ROUTE_TOURS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_CONTEXT_ADOPTION,
  EVOLUTION_CONTEXT_PORTAL,
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOUR,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_V2,
} from 'services/evolution';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
  PROJECT_ROLE_VIEWER,
} from 'services/project';
import {
  BLOCK_TYPE_CHOICE,
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Comments from './component/Comments';
import LastUsersLikedList from './component/LastUsersLikedList';
import Statistics from './component/Statistics';

const logger = new Swaler('PortalPost');

const TAB_STATISTICS = 'Statistics';
const TAB_COMMENTS = 'Comments';

export const PortalPost = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const contentRef = useRef();
  const isScrolled = useScrollDetector(contentRef);

  const match = useRouteMatch();
  const {evolutionId} = match.params;

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const [loading, setLoading] = useState(true);
  const [evolution, setEvolution] = useState(null);
  const [showModalDeleteEvolution, setShowModalDeleteEvolution] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingState, setIsTogglingState] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const [activeTab, setActiveTab] = useState(TAB_STATISTICS);

  const refDropdown = useRef(null);

  useEffect(() => {
    const fetchEvolution = async () => {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'author',
          'comments',
          'comments.likes',
          'tags',
          'steps',
          'analytics',
          'count.votes',
          'tourSteps',
        ],
      });

      if (evolution == null) {
        return logger.error(
          "This experience doesn't exists or has been deleted"
        );
      }

      const isTour = hasFlag(F_BOOST_SLOT_TOUR, evolution.boostFlags);

      if (isTour) {
        const surveysArr = await Promise.all(
          evolution.tourSteps
            ?.filter(
              (t) => hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) !== true
            )
            ?.map(async (t) => ({
              evolutionId: t.uid,
              surveys: await evolutionService.getEvolutionSurveys(t.uid),
            }))
        );

        // Let's redirect to analytic tab if there isn't ask blocks
        if (
          evolution.tourSteps.some((t) =>
            t.steps.some((s) =>
              [
                STEP_TYPE_CONCEPT_TEST,
                STEP_TYPE_OPINION_SCALE,
                STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
                STEP_TYPE_SLIDER,
                STEP_TYPE_NPS,
                STEP_TYPE_TEXT_LONG,
              ].includes(s.type)
            )
          )
        ) {
          setActiveTab(TAB_STATISTICS);
        }
        evolution.tourSteps.forEach((tourStep) => {
          const index = surveysArr
            .map((s) => s.evolutionId)
            .indexOf(tourStep.uid);
          if (index >= 0) {
            const surveys = surveysArr[index].surveys;

            // Let's add nested prototypes steps into evolution.steps
            tourStep.steps = tourStep.steps.reduce((steps, step) => {
              if (step.prototypes.length === 0) {
                return steps.concat(step);
              }
              return steps.concat(step).concat(
                step.prototypes[0].steps.map((ps) => ({
                  ...ps,
                  fromPrototypeStep: {title: step.title, uid: step.uid},
                }))
              );
            }, []);
            // Let's set the step.responses based on surveys
            tourStep.steps = tourStep.steps.map((step) => {
              const stepResponses = surveys.reduce((responses, survey) => {
                const response = survey.responses.find(
                  (r) => r.step.uid === step.uid
                );

                if (response != null) {
                  return [...responses].concat(response);
                }
                return responses;
              }, []);

              step.options = step.options.map((option) => {
                const optionsResponses = stepResponses.reduce(
                  (options, response) => {
                    const optionResponse = response.selectedOptions.find(
                      (o) => o.uid === option.uid
                    );

                    if (optionResponse != null) {
                      return [...options].concat(response);
                    }
                    return options;
                  },
                  []
                );

                return {...option, responses: optionsResponses};
              });
              return {...step, responses: stepResponses};
            });
            tourStep.surveys = surveys;
          }
        });
        setEvolution(evolution);
      } else {
        const surveys = await evolutionService.getEvolutionSurveys(evolutionId);

        // Let's redirect to analytic tab if there isn't ask blocks
        if (
          evolution.steps.some((s) =>
            [
              STEP_TYPE_CONCEPT_TEST,
              STEP_TYPE_OPINION_SCALE,
              STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
              STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
              STEP_TYPE_SLIDER,
              STEP_TYPE_NPS,
              STEP_TYPE_TEXT_LONG,
            ].includes(s.type)
          )
        ) {
          setActiveTab(TAB_STATISTICS);
        }
        // Let's add nested prototypes steps into evolution.steps
        evolution.steps = evolution.steps.reduce((steps, step) => {
          if (step.prototypes.length === 0) {
            return steps.concat(step);
          }
          return steps.concat(step).concat(
            step.prototypes[0].steps.map((ps) => ({
              ...ps,
              fromPrototypeStep: {title: step.title, uid: step.uid},
            }))
          );
        }, []);
        // Let's set the step.responses based on surveys
        evolution.steps = evolution.steps.map((step) => {
          const stepResponses = surveys.reduce((responses, survey) => {
            const response = survey.responses.find(
              (r) => r.step.uid === step.uid
            );

            if (response != null) {
              return [...responses].concat(response);
            }
            return responses;
          }, []);
          if (
            [
              STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
              STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
            ].includes(step.type)
          ) {
            const choiceBlock = step.blocks?.find(
              (b) => b.type === BLOCK_TYPE_CHOICE
            );
            if (choiceBlock != null) {
              step.options = choiceBlock.options;
            }
          }
          step.options = step.options.map((option) => {
            const optionsResponses = stepResponses.reduce(
              (options, response) => {
                const optionResponse = response.selectedOptions.find(
                  (o) => o.uid === option.uid
                );

                if (optionResponse != null) {
                  return [...options].concat(response);
                }
                return options;
              },
              []
            );

            return {...option, responses: optionsResponses};
          });
          return {...step, responses: stepResponses};
        });
        setEvolution({...evolution, surveys});
      }

      setLoading(false);
      setRefetch(false);

      // if #comments in url, let's set active tab to comments
      if (window.location.hash === '#comments') {
        setActiveTab(TAB_COMMENTS);
      }
    };

    if (refetch === true) {
      fetchEvolution();
    }
  }, [refetch]);

  const handleShowHideModalDeleteEvolution = (
    value = !showModalDeleteEvolution
  ) => setShowModalDeleteEvolution(value);
  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      amplitude.getInstance().logEvent(EVENT_DELETE_EVOLUTION, {
        from: 'Post',
      });
      await evolutionService.deleteEvolution(evolution.uid);
      toastSuccess('Post deleted 👍', {toastId: 'evolution-deleted'});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting post failed with error ', code);
      toastDanger([title, message], {actions});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
    }
  };

  const handleToggleState = async (live) => {
    setIsTogglingState(true);
    try {
      if (live === true) {
        amplitude.getInstance().logEvent(EVENT_PLAY_POKE, {
          from: 'Post',
        });
      } else {
        amplitude.getInstance().logEvent(EVENT_PAUSE_POKE, {
          from: 'Post',
        });
      }
      await evolutionService.updateEvolution(evolutionId, {
        state: live === true ? EVOLUTION_STATE_LIVE : EVOLUTION_STATE_PAUSED,
      });
      toastSuccess('Experience updated 👍', {toastId: 'poke-updated'});
      setRefetch(true);
      setIsTogglingState(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating experience failed with error ', code);
      toastDanger([title, message], {actions});
      setIsTogglingState(false);
    }
  };

  const handleExportResponses = async (type) => {
    const isDiscovery = evolution.steps.length > 0; // Use evolution.context when new builder is pushed

    const responses =
      isDiscovery === true
        ? await evolutionService.exportDiscoveryResponses(evolution.uid, type)
        : await evolutionService.exportAdoptionResponses(evolution.uid, type);
    const fileName = `responses-${evolution.uid}.${type}`;
    const data =
      type === 'json'
        ? new Blob([JSON.stringify(responses)], {type: 'text/json'})
        : new Blob([responses], {type: 'text/csv'});
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    refDropdown.current.close();
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  const classNames = classnames('s-changelog-post', {
    'is-empty': evolution == null,
    'is-loading': loading === true,
    'fade-in-bottom': loading === false,
  });

  const shownOnPortal = hasFlag(
    F_OPTION_SHOW_ON_PORTAL,
    evolution?.optionsFlags
  );
  const isPoke = evolution?.boostFlags > 0;

  const isDraft = evolution?.isDraft;
  const lastStepChangeAt = evolution?.lastStepChangeAt;
  const expiresAt = evolution?.expiresAt;
  const context = evolution?.context;
  let extraTag = null;
  const curDate = new Date();

  if (
    isDraft !== true &&
    new Date(lastStepChangeAt).getTime() > curDate.getTime()
  ) {
    const publishDate = new Date(lastStepChangeAt);

    if (publishDate.getTime() > curDate.getTime()) {
      extraTag = scheduledTag(lastStepChangeAt);
    }
  }
  if (isDraft !== true && extraTag == null && expiresAt != null) {
    extraTag = expiredTag(expiresAt);
  }

  const isLive =
    isDraft !== true &&
    (evolution?.state === EVOLUTION_STATE_LIVE || evolution?.state == null);
  const isPaused =
    isDraft !== true && evolution?.state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());

  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());

  const canEditPush = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_EDITOR,
    PROJECT_ROLE_MEMBER,
  ].includes(projectMember.role);
  const canExportResponses = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_MEMBER,
    PROJECT_ROLE_VIEWER,
  ].includes(projectMember.role);

  const isOldPoke = hasFlag(F_OPTION_V2, evolution?.optionsFlags) !== true;
  const isOldVersionPost = !(evolution?.steps?.[0]?.blocks?.length > 0);

  const isCommentExportable = false; // TODO set to true when needed

  return (
    <div className={classNames}>
      {evolution != null && (
        <div className={classnames('poke-header', {'is-scrolled': isScrolled})}>
          <div className="infos">
            <Button
              className="back-btn"
              iconOnly
              iconLeft="icon-chevron-left"
              onClick={async () => {
                if (routeMatch.path === ROUTE_BOOSTED_WITH_ID()) {
                  history.push(ROUTE_TOURS);
                }
                if (routeMatch.path === ROUTE_FEED_EVOLUTION()) {
                  history.push(ROUTE_FEED);
                }
                if (routeMatch.path === ROUTE_ROADMAP_EVOLUTION()) {
                  history.push(ROUTE_ROADMAP);
                }
              }}
            />
            <div>
              {evolutionIcons.find((i) => i.value === 'CHANGELOG').icon}
            </div>
            <div className="push-title-wrapper">
              <div className="push-title title-4">{evolution.title}</div>
              <div className="created-at body-3 n-500">
                Changelog &bull; Created{' '}
                {dayjs(evolution.createdAt).format('MMM DD, YYYY')}
              </div>
            </div>
          </div>
          <div className="actions">
            {canEditPush && (
              <>
                {isDraft !== true && isPoke && (
                  <Button
                    className={classnames('toggle-state-btn', {
                      resume: isPaused,
                      pause: isLive,
                    })}
                    disabled={isExpired || isScheduled}
                    thin
                    loading={isTogglingState}
                    iconLeft={
                      isLive ? 'icon-pause-rounded' : 'icon-play-rounded'
                    }
                    onClick={() => handleToggleState(isLive ? false : true)}>
                    {isExpired === true
                      ? 'expired'
                      : isScheduled === true
                      ? 'scheduled'
                      : isLive
                      ? 'Pause'
                      : 'Play'}
                  </Button>
                )}
                {isOldPoke !== true && (
                  <Button
                    iconLeft="icon-edit-outline"
                    onClick={() => {
                      amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
                        from: 'Post',
                      });
                      if (
                        shownOnPortal ||
                        evolution?.context === EVOLUTION_CONTEXT_PORTAL
                      ) {
                        if (isOldVersionPost === true) {
                          return history.push(
                            ROUTE_BUILDER_CREATE({evolutionId: evolution?.uid})
                          );
                        } else {
                          return history.push(
                            ROUTE_POST_BUILDER_WRITER(evolution?.uid)
                          );
                        }
                      }
                    }}>
                    Edit Post
                  </Button>
                )}
                <Dropdown
                  className="dropdown-more-actions"
                  triggerClassName="menu-dropdown-trigger"
                  trigger={
                    <Button className="menu-btn">
                      <i className="isax isax-more" />
                    </Button>
                  }
                  position="bottom right"
                  offsetY={8}>
                  <Menu>
                    <MenuItem
                      onClick={() => {
                        handleShowHideModalDeleteEvolution(true);
                        setShowModalDeleteEvolution(true);
                      }}>
                      <i className="icon-trash" />
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      )}
      <div className="changelog-content-wrapper" ref={contentRef}>
        {loading ? (
          <div className={classNames}>
            <Loader dark width="24px" />
          </div>
        ) : evolution == null ? (
          <div className={classNames}>
            <div className="main">
              Select an evolution
              <br /> to see details
            </div>
          </div>
        ) : (
          <>
            <div className="changelog-content">
              <div className="section">
                <div className="section-title">
                  Insights
                  {canExportResponses === true && isCommentExportable && (
                    <Dropdown
                      className="action-export-data"
                      innerRef={refDropdown}
                      position="bottom center"
                      trigger={
                        <Button
                          className="btn-export-data"
                          iconLeft="icon-download">
                          Export comments
                        </Button>
                      }>
                      <Menu className="menu-export-data">
                        <MenuItem onClick={() => handleExportResponses('json')}>
                          Export to <span>JSON</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleExportResponses('csv')}>
                          Export to <span>CSV</span>
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="tabs-wrapper">
                  <Tabs defaultTab={activeTab} onTabChange={setActiveTab}>
                    <div label={TAB_STATISTICS} />
                    <div label={TAB_COMMENTS} />
                  </Tabs>
                </div>
                <div className="section-content">
                  {isOldPoke !== true &&
                  context === EVOLUTION_CONTEXT_ADOPTION ? (
                    <Statistics evolution={evolution} />
                  ) : (
                    <>
                      {activeTab === TAB_STATISTICS && (
                        <>
                          <Statistics evolution={evolution} />
                          <LastUsersLikedList evolution={evolution} />
                          <ListLastUsersReached evolution={evolution} />
                        </>
                      )}
                      {activeTab === TAB_COMMENTS && (
                        <Comments
                          evolution={evolution}
                          setEvolution={setEvolution}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <ModalConfirm
          isOpen={showModalDeleteEvolution}
          isConfirming={isDeleting}
          confirmText="Yes, delete"
          onConfirm={handleDeleteEvolution}
          onCancel={() => handleShowHideModalDeleteEvolution(false)}
        />
      </div>
    </div>
  );
};
