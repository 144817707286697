import {EVENT_LAUNCH_PUSH} from 'amplitude';
import amplitude from 'amplitude-js';
import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  expiredTag,
  scheduledTag,
} from 'components/EvolutionList/components/Evolution';
import ModalInstall from 'components/ModalInstall';
import {PokeTagSelector} from 'components/PokeTagSelector';
import SharableLinkModal from 'components/SharableLinkModal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {PermissionsPoke, PermissionsPost} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {errorCodes} from 'helpers/error';
import {hasPermissions} from 'helpers/permission';
import {isAudienceValid, isPokeValid} from 'helpers/poke';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {bool} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_BANNER_WITH_ID,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_FEED_EVOLUTION,
  ROUTE_HINT_WITH_ID,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOUR_WITH_ID,
} from 'router/routes.const';
import {AudienceContext} from 'scenes/PokeAudience';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {F_EXTRA_CAN_PUBLISH} from 'services/project';
import {
  hasAnnualBilling,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('AudienceHeader');

const propTypes = {
  isPost: bool,
};

const defaultProps = {
  isPost: false,
};

export const getPokeRoute = (type) => {
  if (type === EVOLUTION_TYPE_TOUR) {
    return ROUTE_TOUR_WITH_ID;
  } else if (type === EVOLUTION_TYPE_SURVEY) {
    return ROUTE_SURVEY_WITH_ID;
  } else if (type === EVOLUTION_TYPE_BANNER) {
    return ROUTE_BANNER_WITH_ID;
  } else if (type === EVOLUTION_TYPE_HINT) {
    return ROUTE_HINT_WITH_ID;
  } else if (type === EVOLUTION_TYPE_CHECKLIST) {
    return ROUTE_CHECKLIST_WITH_ID;
  }
};

const AudienceHeader = ({isPost, scrolled}) => {
  const {update} = useUpdateSubscription();

  const {evolution, save} = useContext(AudienceContext);

  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [troubleshootingMenuOpen, setTroubleshootingMenuOpen] = useState(false);

  const isLinkedPostNotPublished = evolution.isBoostOf?.isDraft === true;

  useEffect(() => {
    if (showTestModal === false) return;
    save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTestModal]);

  const handleSaveClick = async () => {
    setIsSubmitting(true);
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      toastSuccess(['Changes saved'], {toastId: 'changes-saved'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Saving failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePublishClick = async () => {
    setIsSubmitting(true);
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });

      if (project.snippetInstalledAt == null) {
        setShowInstallModal(true);
        setIsSubmitting(false);
        return;
      }

      if (evolution.isDraft === true) {
        if (hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false) {
          setIsSubmitting(false);
          return update({
            planId: PLAN_STARTUP_ID,
            title: 'Publish your experiences',
            description:
              'Publish your experiences and reach your users with the Startup plan.',
          });
        }
        if (isLinkedPostNotPublished === true) {
          await evolutionService.publish(evolution.isBoostOf.uid);
        }
        await evolutionService.publish(evolution.uid);
      }
      toastSuccess(
        [evolution.isDraft === true ? 'Published' : 'Changes saved'],
        {toastId: 'changes-saved'}
      );
      setIsSubmitting(false);

      if (evolution.isDraft !== false) {
        amplitude.getInstance().logEvent(EVENT_LAUNCH_PUSH, {
          isPoke: isPost !== true,
          isPost: isPost === true,
        });
      }

      if (isPost === true) {
        return history.push(ROUTE_FEED_EVOLUTION(evolution.uid));
      }
      const route = getPokeRoute(evolution.type);
      history.push(
        route(evolution.uid, {
          showSuccess:
            evolution.isDraft === true && isLinkedPostNotPublished !== true,
          showSuccessWithPost:
            evolution.isDraft === true && isLinkedPostNotPublished === true,
        })
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsSubmitting(false);
      if (code === errorCodes.LIVE_SURVEYS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          modeAnnualBilling: hasAnnualBilling(),
        });
      }
      if (code === errorCodes.LIVE_CHECKLIST_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited checklists',
          description: 'Publish unlimited checklists with the Growth plan.',
        });
      }
      setIsSubmitting(false);
      logger.error('Saving failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const {
    isDraft: isEvolutionDraft,
    lastStepChangeAt,
    expiresAt,
    type,
  } = evolution;
  let extraTag = null;
  const curDate = new Date();

  if (
    isEvolutionDraft !== true &&
    new Date(lastStepChangeAt).getTime() > curDate.getTime()
  ) {
    const publishDate = new Date(lastStepChangeAt);

    if (publishDate.getTime() > curDate.getTime()) {
      extraTag = scheduledTag(lastStepChangeAt);
    }
  }
  if (isEvolutionDraft !== true && extraTag == null && expiresAt != null) {
    extraTag = expiredTag(expiresAt);
  }

  const isDraft = isEvolutionDraft === true ? true : false;

  const isAudienceSubmitDisabled = isAudienceValid(evolution) !== true;

  const canPublish =
    isPost === true
      ? hasPermissions(PermissionsPost.PUBLISH)
      : hasPermissions(PermissionsPoke.PUBLISH);

  let issues = isPost === true ? true : isPokeValid(evolution);

  const isSubmitDisabled = issues !== true;

  const isTour = type === EVOLUTION_TYPE_TOUR;
  const isSurvey = type === EVOLUTION_TYPE_SURVEY;
  const isBanner = type === EVOLUTION_TYPE_BANNER;
  const isHint = type === EVOLUTION_TYPE_HINT;
  const isChecklist = type === EVOLUTION_TYPE_CHECKLIST;

  const isAdoption = isTour || isBanner || isHint;
  const isDiscovery = isSurvey;

  return (
    <div className={classNames('audience-header', {scrolled: scrolled})}>
      <div className="infos">
        <Button
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={async () => {
            if (isPost === true) {
              return history.push(ROUTE_FEED_EVOLUTION(evolution?.uid));
            }
            const route = getPokeRoute(evolution.type);
            history.push(route(evolution?.uid));
          }}
        />
        <div
          className={classNames('icon-wrapper', {
            adoption: isAdoption,
            discovery: isDiscovery,
            'is-checklist': isChecklist,
            'is-changelog': isPost,
          })}>
          {isPost && <i className="isax isax-slider-vertical5" />}
          {isTour && <i className="isax isax-routing-25" />}
          {isDiscovery && <i className="isax isax-note-215" />}
          {isBanner && <i className="icon-slot-top-bar-bold" />}
          {isHint && <i className="icon-slot-dot-bold" />}
          {isChecklist && <i className="isax isax-task-square5" />}
        </div>
        <div className="push-title-wrapper">
          <div className="push-title title-4">{evolution.title}</div>
          <div className="created-at body-3 n-500">
            {isPost
              ? 'Changelog'
              : isTour
              ? 'Tour'
              : isSurvey
              ? 'Survey'
              : isBanner
              ? 'Banner'
              : isHint
              ? 'Hint'
              : isChecklist
              ? 'Checklist'
              : ''}{' '}
            &bull; Created {dayjs(evolution.createdAt).format('MMM DD, YYYY')}
          </div>
        </div>
        {isPost !== true && <PokeTagSelector poke={evolution} />}
      </div>
      <div className="actions">
        {isPost !== true && (
          <Button
            thin
            className="btn-test"
            disabled={isSubmitting}
            iconLeft="isax isax-play-circle"
            onClick={() => setShowTestModal(true)}>
            Test
          </Button>
        )}

        {evolution.isDraft === true && (
          <Button
            thin
            className="btn-save"
            loading={isSubmitting}
            onClick={handleSaveClick}>
            Save changes
          </Button>
        )}

        {canPublish && (
          <>
            {isSubmitDisabled === true ? (
              <Dropdown
                className="dropdown-troubleshooting-to-edit"
                position="bottom right"
                open={troubleshootingMenuOpen}
                offsetY={4}
                on={['hover']}
                onClose={() => setTroubleshootingMenuOpen(false)}
                trigger={
                  <Button
                    thin
                    primary
                    className={classNames('btn-publish', {
                      'is-invalid': isSubmitDisabled,
                    })}
                    loading={isSubmitting}
                    disabled
                    onClick={() => setTroubleshootingMenuOpen(true)}>
                    Target & publish
                    <div className="icon-wrapper">
                      <i className="icon-exclamation-triangle-o" />
                    </div>
                  </Button>
                }>
                <div
                  className="troubleshooting-to-edit"
                  onClick={() => {
                    history.push(
                      ROUTE_POKE_BUILDER_FROM_TYPE(
                        evolution.uid,
                        evolution.type
                      )
                    );
                  }}>
                  You have to fix your experience issues before publishing. Go
                  back to editing by clicking here
                </div>
              </Dropdown>
            ) : (
              <Tooltip
                className="tooltip-poke-post-publish"
                disabled={isDraft !== true || isLinkedPostNotPublished !== true}
                arrow={true}
                trigger={
                  <div>
                    <Button
                      thin
                      primary
                      className="btn-publish"
                      iconRight={
                        evolution.isDraft === true
                          ? 'icon-arrow-right'
                          : undefined
                      }
                      loading={isSubmitting}
                      disabled={isAudienceSubmitDisabled}
                      onClick={handlePublishClick}>
                      {isDraft === true
                        ? isLinkedPostNotPublished === true
                          ? 'Publish Post & Experience'
                          : 'Publish'
                        : 'Publish changes'}
                    </Button>
                  </div>
                }>
                This will simultaneously publish your experience and the post it
                is linked to.
              </Tooltip>
            )}
          </>
        )}
      </div>
      <ModalInstall
        isOpen={showInstallModal}
        onRequestClose={() => setShowInstallModal(false)}
        subtitle="To publish your first experience, you need to install Jimo by choosing one of those 3 methods :"
      />
      {showTestModal && (
        <SharableLinkModal
          isOpen={showTestModal}
          onRequestClose={() => setShowTestModal(false)}
          evolution={evolution}
          isPreview
        />
      )}
    </div>
  );
};

AudienceHeader.propTypes = propTypes;
AudienceHeader.defaultProps = defaultProps;

export default AudienceHeader;
